<template>
  <div>
    <v-dialog
      v-model="dialog"
      width="680px"
      persistent
      overlay-color="transparent"
      :eager="true"
    >
      <v-form ref="form_protocol" v-model="valid">
        <v-card max-height="100vh">

          <div>
            <v-system-bar dark color="primary">
              <v-icon class="mr-1">mdi-pencil</v-icon>
              <small><strong class="text-uppercase">{{ $t('COMMON.MODIFY') }}</strong></small>
              <v-spacer/>
              <v-icon
                small
                class="clickable"
                @click="closeDialog"
              >
                mdi-close
              </v-icon>
            </v-system-bar>

            <v-toolbar
              color="transparent"
              flat
              dense
              height="60px"
            >
              <v-toolbar-title>{{ $t('JOB.ADVANCED_PARAMS') }}</v-toolbar-title>
              <v-spacer />
              <v-btn
                x-small
                fab
                depressed
                color="error"
                class="mr-4"
                @click="closeDialog"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-btn
                rounded
                depressed
                color="success"
                :disabled="!valid"
                @click="apply"
              >
                <v-icon class="mr-2">mdi-check</v-icon>
                {{ $t('COMMON.APPLY') }}
              </v-btn>
            </v-toolbar>

            <div class="toolbar-separator primary ml-4 mr-4"/>
          </div>

          <div class="pa-6 pt-0 scroll" style="max-height: calc(80vh - 61px)">

            <p class="primary--text text-nowrap mt-4">{{ $t('JOB.SALARY') }}</p>
            <v-row class="mt-0 mb-0">
              <v-col cols="2" class="pt-0 pb-0">
                <v-text-field
                  dense
                  v-model="salary_min"
                  @change="$emit('input', {salary_min: $event})"
                  type="number"
                  min="0"
                  :rule="[$rules.min(0)]"
                  :label="$t('JOB.FIELD_SALARY_MIN')"
                  :placeholder="$t('JOB.FIELD_SALARY_MIN_PLACEHOLDER')"
                  outlined
                />
              </v-col>
              <v-col cols="2" class="pt-0 pb-0 pl-0">
                <v-text-field
                  dense
                  v-model="salary_max"
                  @change="$emit('input', {salary_max: $event})"
                  type="number"
                  min="0"
                  :rule="[$rules.min(0)]"
                  :label="$t('JOB.FIELD_SALARY_MAX')"
                  :placeholder="$t('JOB.FIELD_SALARY_MAX_PLACEHOLDER')"
                  outlined
                />
              </v-col>
              <v-col cols="1" class="pt-0 pb-0">
                <div class="pt-2 grey--text text-center">/</div>
              </v-col>
              <v-col cols="3" class="pt-0 pb-0">
                <v-select
                  dense
                  v-model="salary_period"
                  @change="$emit('input', {salary_period: $event})"
                  :items="periodItems"
                  :label="$t('JOB.FIELD_SALARY_PERIOD')"
                  :placeholder="$t('JOB.FIELD_SALARY_PERIOD_PLACEHOLDER')"
                  outlined
                />
              </v-col>
              <v-col cols="1" class="pt-0 pb-0"/>
              <v-col cols="3" class="pt-0 pb-0">
                <v-select
                dense
                v-model="salary_currency"
                @change="$emit('input', {salary_currency: $event})"
                :items="currencyItems"
                :label="$t('JOB.FIELD_SALARY_CURRENCY')"
                :placeholder="$t('JOB.FIELD_SALARY_CURRENCY_PLACEHOLDER')"
                outlined
                />
              </v-col>
            </v-row>

            <v-row class="mt-0 mb-0">
              <v-col cols="4" class="pt-0 pb-0">
                <v-select
                  dense
                  v-model="contract_status"
                  @change="$emit('input', {contract_status: $event})"
                  :items="contratStatusItems"
                  :label="$t('JOB.FIELD_CONTRACT_STATUS')"
                  outlined
                  class="mt-0"
                />
              </v-col>
            </v-row>

            <p class="primary--text text-nowrap">{{ $t('JOB.DURATION') }}</p>
            <v-row class="mt-0 mb-0">
              <v-col cols="2" class="pt-0 pb-0">
                <v-text-field
                  dense
                  v-model="duration_min"
                  @change="$emit('input', {duration_min: $event})"
                  type="number"
                  min="0"
                  :rule="[$rules.min(0)]"
                  :label="$t('JOB.FIELD_DURATION_MIN')"
                  :placeholder="$t('JOB.FIELD_DURATION_MIN_PLACEHOLDER')"
                  outlined
                />
              </v-col>
              <v-col cols="2" class="pt-0 pb-0 pl-0">
                <v-text-field
                  dense
                  v-model="duration_max"
                  @change="$emit('input', {duration_max: $event})"
                  type="number"
                  min="0"
                  :rule="[$rules.min(0)]"
                  :label="$t('JOB.FIELD_DURATION_MAX')"
                  :placeholder="$t('JOB.FIELD_DURATION_MAX_PLACEHOLDER')"
                  outlined
                />
              </v-col>
              <v-col cols="1" class="pt-0 pb-0">
                <div class="pt-2 grey--text text-center">/</div>
              </v-col>
              <v-col cols="3" class="pt-0 pb-0">
                <v-select
                  dense
                  v-model="duration_period"
                  @change="$emit('input', {duration_period: $event})"
                  :items="periodItems"
                  :label="$t('JOB.FIELD_DURATION_PERIOD')"
                  :placeholder="$t('JOB.FIELD_DURATION_PERIOD_PLACEHOLDER')"
                  outlined
                />
              </v-col>
            </v-row>

            <p class="primary--text text-nowrap">{{ $t('JOB.CONTRACT_DATE') }}</p>
            <v-row class="mt-0 mb-0">
              <v-col cols="6" class="pt-0 pb-0">
                <v-text-field
                  dense
                  v-model="contract_start_date"
                  @change="$emit('input', {contract_start_date: $event})"
                  :label="$t('JOB.FIELD_CONTRACT_START_DATE')"
                  :placeholder="$t('JOB.FIELD_CONTRACT_START_DATE_PLACEHOLDER')"
                  outlined
                />
              </v-col>
              <v-col cols="6" class="pt-0 pb-0 pl-0">
                <v-text-field
                  dense
                  v-model="contract_end_date"
                  @change="$emit('input', {contract_end_date: $event})"
                  :label="$t('JOB.FIELD_CONTRACT_END_DATE')"
                  :placeholder="$t('JOB.FIELD_CONTRACT_END_DATE_PLACEHOLDER')"
                  outlined
                />
              </v-col>
            </v-row>

            <p class="primary--text text-nowrap">{{ $t('JOB.AGES') }}</p>
            <v-row class="mt-0 mb-0">
              <v-col cols="2" class="pt-0 pb-0">
                <v-text-field
                  dense
                  v-model="age_min"
                  @change="$emit('input', {age_min: $event})"
                  type="number"
                  min="0"
                  :rule="[$rules.min(0)]"
                  :label="$t('JOB.FIELD_AGE_MIN')"
                  :placeholder="$t('JOB.FIELD_AGE_MIN_PLACEHOLDER')"
                  outlined
                />
              </v-col>
              <v-col cols="2" class="pt-0 pb-0 pl-0">
                <v-text-field
                  dense
                  v-model="age_max"
                  @change="$emit('input', {age_max: $event})"
                  type="number"
                  min="0"
                  :rule="[$rules.min(0)]"
                  :label="$t('JOB.FIELD_AGE_MAX')"
                  :placeholder="$t('JOB.FIELD_AGE_MAX_PLACEHOLDER')"
                  outlined
                />
              </v-col>
            </v-row>


            <p class="primary--text text-nowrap">{{ $t('JOB.EXPERIENCE') }}</p>
            <v-row class="mt-0 mb-0">
              <v-col cols="6" class="pt-0 pb-0">
                <v-select
                  dense
                  v-model="experience_level"
                  @change="$emit('input', {experience_level: $event})"
                  :items="experienceItems"
                  :label="$t('JOB.FIELD_EXPERIENCE_LEVEL')"
                  :placeholder="$t('JOB.FIELD_EXPERIENCE_LEVEL_PLACEHOLDER')"
                  outlined
                />
              </v-col>
            </v-row>


            <p class="primary--text text-nowrap">{{ $t('JOB.EDUCATION') }}</p>
            <v-row class="mt-0 mb-0">
              <v-col cols="6" class="pt-0 pb-0">
                <v-select
                  dense
                  v-model="education_level"
                  @change="$emit('input', {education_level: $event})"
                  :items="educationItems"
                  :label="$t('JOB.FIELD_EDUCATION_LEVEL')"
                  :placeholder="$t('JOB.FIELD_EDUCATION_LEVEL_PLACEHOLDER')"
                  outlined
                />
              </v-col>
            </v-row>

            <v-row class="mt-0 mb-0">
              <v-col cols="12" class="pt-0 pb-0">
                <v-combobox
                  dense
                  v-model="diplomas"
                  @change="$emit('input', {diplomas: $event})"
                  :label="$t('JOB.FIELD_DIPLOMAS')"
                  :placeholder="$t('JOB.FIELD_DIPLOMAS_PLACEHOLDER')"
                  multiple
                  small-chips
                  outlined
                  required
                />
              </v-col>
            </v-row>


            <p class="primary--text text-nowrap">{{ $t('JOB.QUANTITY') }}</p>
            <v-row class="mt-0 mb-0">
              <v-col cols="2" class="pt-0 pb-0">
                <v-text-field
                  dense
                  v-model="quantity"
                  @change="$emit('input', {quantity: $event})"
                  type="number"
                  min="0"
                  :rule="[$rules.min(0)]"
                  :label="$t('JOB.FIELD_QUANTITY')"
                  :placeholder="$t('JOB.FIELD_QUANTITY_PLACEHOLDER')"
                  outlined
                />
              </v-col>
            </v-row>

            <p class="primary--text text-nowrap">{{ $t('JOB.PUBLICATION_DATES') }}</p>
            <v-row class="mt-0 mb-0">
              <v-col cols="6" class="pt-0 pb-0">
                <v-text-field
                  dense
                  v-model="start_date"
                  @change="$emit('input', {start_date: $event})"
                  :label="$t('JOB.FIELD_START_DATE')"
                  :placeholder="$t('JOB.FIELD_START_DATE_PLACEHOLDER')"
                  outlined
                />
              </v-col>
              <v-col cols="6" class="pt-0 pb-0 pl-0">
                <v-text-field
                  dense
                  v-model="end_date"
                  @change="$emit('input', {end_date: $event})"
                  :label="$t('JOB.FIELD_END_DATE')"
                  :placeholder="$t('JOB.FIELD_END_DATE_PLACEHOLDER')"
                  outlined
                />
              </v-col>
            </v-row>

          </div>
        </v-card>
      </v-form>
    </v-dialog>

    <div>
      <span class="primary--text">{{ $t('JOB.ADVANCED_PARAMS') }}</span>
      <v-btn
        small
        rounded
        depressed
        color="secondary black--text"
        class="ml-4 mr-4"
        @click="showDialog"
      >
        {{ $t('COMMON.MODIFY') }}
      </v-btn>
      <!-- <span class="grey--text text-medium">
        {{ $tc('JOB.PROFILE_VALIDATION', values.profile.length) }}
      </span> -->
      <v-text-field
        name="form_description"
        style="display:none;"
        :value="valid"
        :rules="[$rules.required]"
      />
    </div>

    <div class="mt-4">

      <template v-if="values.salary_min">
        <v-divider />
        <v-row class="mt-0 mb-0 text-medium">
          <v-col cols="2" class="text-ellipsis text-nowrap grey--text" :title="$t('JOB.SALARY_MIN')">
            {{ $t('JOB.SALARY_MIN') }}
          </v-col>
          <v-col cols="10" class="text-ellipsis text-nowrap">
            <span>{{ values.salary_min }}</span>
          </v-col>
        </v-row>
      </template>

      <template v-if="values.salary_max">
        <v-divider />
        <v-row class="mt-0 mb-0 text-medium">
          <v-col cols="2" class="text-ellipsis text-nowrap grey--text" :title="$t('JOB.SALARY_MAX')">
            {{ $t('JOB.SALARY_MAX') }}
          </v-col>
          <v-col cols="10" class="text-ellipsis text-nowrap">
            <span>{{ values.salary_max }}</span>
          </v-col>
        </v-row>
      </template>

      <template v-if="values.salary_period && (values.salary_min || values.salary_max)">
        <v-divider />
        <v-row class="mt-0 mb-0 text-medium">
          <v-col cols="2" class="text-ellipsis text-nowrap grey--text" :title="$t('JOB.SALARY_PERIOD')">
            {{ $t('JOB.SALARY_PERIOD') }}
          </v-col>
          <v-col cols="10" class="text-ellipsis text-nowrap">
            <span>{{ values.salary_period }}</span>
          </v-col>
        </v-row>
      </template>

      <template v-if="values.salary_currency && (values.salary_min || values.salary_max)">
        <v-divider />
        <v-row class="mt-0 mb-0 text-medium">
          <v-col cols="2" class="text-ellipsis text-nowrap grey--text" :title="$t('JOB.SALARY_CURRENCY')">
            {{ $t('JOB.SALARY_CURRENCY') }}
          </v-col>
          <v-col cols="10" class="text-ellipsis text-nowrap">
            <span>{{ values.salary_currency }}</span>
          </v-col>
        </v-row>
      </template>

      <template v-if="values.contract_status">
        <v-divider />
        <v-row class="mt-0 mb-0 text-medium">
          <v-col cols="2" class="text-ellipsis text-nowrap grey--text" :title="$t('JOB.CONTRACT_STATUS')">
            {{ $t('JOB.CONTRACT_STATUS') }}
          </v-col>
          <v-col cols="10" class="text-ellipsis text-nowrap">
            <span>{{ values.contract_status }}</span>
          </v-col>
        </v-row>
      </template>

      <template v-if="values.contract_start_date">
        <v-divider />
        <v-row class="mt-0 mb-0 text-medium">
          <v-col cols="2" class="text-ellipsis text-nowrap grey--text" :title="$t('JOB.FIELD_CONTRACT_START_DATE')">
            {{ $t('JOB.FIELD_CONTRACT_START_DATE') }}
          </v-col>
          <v-col cols="10" class="text-ellipsis text-nowrap">
            <span>{{ new Date(values.contract_start_date).toLocaleDateString() }}</span>
          </v-col>
        </v-row>
      </template>

      <template v-if="values.contract_end_date">
        <v-divider />
        <v-row class="mt-0 mb-0 text-medium">
          <v-col cols="2" class="text-ellipsis text-nowrap grey--text" :title="$t('JOB.FIELD_CONTRACT_END_DATE')">
            {{ $t('JOB.FIELD_CONTRACT_END_DATE') }}
          </v-col>
          <v-col cols="10" class="text-ellipsis text-nowrap">
            <span>{{ new Date(values.contract_end_date).toLocaleDateString() }}</span>
          </v-col>
        </v-row>
      </template>

      <template v-if="values.duration_min">
        <v-divider />
        <v-row class="mt-0 mb-0 text-medium">
          <v-col cols="2" class="text-ellipsis text-nowrap grey--text" :title="$t('JOB.DURATION_MIN')">
            {{ $t('JOB.DURATION_MIN') }}
          </v-col>
          <v-col cols="10" class="text-ellipsis text-nowrap">
            <span>{{ values.duration_min }}</span>
          </v-col>
        </v-row>
      </template>

      <template v-if="values.duration_max">
        <v-divider />
        <v-row class="mt-0 mb-0 text-medium">
          <v-col cols="2" class="text-ellipsis text-nowrap grey--text" :title="$t('JOB.DURATION_MAX')">
            {{ $t('JOB.DURATION_MAX') }}
          </v-col>
          <v-col cols="10" class="text-ellipsis text-nowrap">
            <span>{{ values.duration_max }}</span>
          </v-col>
        </v-row>
      </template>

      <template v-if="values.duration_period && (values.duration_min || values.duration_max)">
        <v-divider />
        <v-row class="mt-0 mb-0 text-medium">
          <v-col cols="2" class="text-ellipsis text-nowrap grey--text" :title="$t('JOB.DURATION_PERIOD')">
            {{ $t('JOB.DURATION_PERIOD') }}
          </v-col>
          <v-col cols="10" class="text-ellipsis text-nowrap">
            <span>{{ values.duration_period }}</span>
          </v-col>
        </v-row>
      </template>

      <template v-if="values.start_date">
        <v-divider />
        <v-row class="mt-0 mb-0 text-medium">
          <v-col cols="2" class="text-ellipsis text-nowrap grey--text" :title="$t('JOB.START_DATE')">
            {{ $t('JOB.START_DATE') }}
          </v-col>
          <v-col cols="10" class="text-ellipsis text-nowrap">
            <span>{{ values.start_date }}</span>
          </v-col>
        </v-row>
      </template>

      <template v-if="values.end_date">
        <v-divider />
        <v-row class="mt-0 mb-0 text-medium">
          <v-col cols="2" class="text-ellipsis text-nowrap grey--text" :title="$t('JOB.END_DATE')">
            {{ $t('JOB.END_DATE') }}
          </v-col>
          <v-col cols="10" class="text-ellipsis text-nowrap">
            <span>{{ values.end_date }}</span>
          </v-col>
        </v-row>
      </template>

      <template v-if="values.age_min">
        <v-divider />
        <v-row class="mt-0 mb-0 text-medium">
          <v-col cols="2" class="text-ellipsis text-nowrap grey--text" :title="$t('JOB.AGE_MIN')">
            {{ $t('JOB.AGE_MIN') }}
          </v-col>
          <v-col cols="10" class="text-ellipsis text-nowrap">
            <span>{{ values.age_min }}</span>
          </v-col>
        </v-row>
      </template>

      <template v-if="values.age_max">
        <v-divider />
        <v-row class="mt-0 mb-0 text-medium">
          <v-col cols="2" class="text-ellipsis text-nowrap grey--text" :title="$t('JOB.AGE_MAX')">
            {{ $t('JOB.AGE_MAX') }}
          </v-col>
          <v-col cols="10" class="text-ellipsis text-nowrap">
            <span>{{ values.age_max }}</span>
          </v-col>
        </v-row>
      </template>

      <template v-if="values.experience_level">
        <v-divider />
        <v-row class="mt-0 mb-0 text-medium">
          <v-col cols="2" class="text-ellipsis text-nowrap grey--text" :title="$t('JOB.EXPERIENCE_LEVEL')">
            {{ $t('JOB.EXPERIENCE_LEVEL') }}
          </v-col>
          <v-col cols="10" class="text-ellipsis text-nowrap">
            <span>{{ values.experience_level }}</span>
          </v-col>
        </v-row>
      </template>

      <template v-if="values.education_level">
        <v-divider />
        <v-row class="mt-0 mb-0 text-medium">
          <v-col cols="2" class="text-ellipsis text-nowrap grey--text" :title="$t('JOB.EDUCATION_LEVEL')">
            {{ $t('JOB.EDUCATION_LEVEL') }}
          </v-col>
          <v-col cols="10" class="text-ellipsis text-nowrap">
            <span>{{ values.education_level }}</span>
          </v-col>
        </v-row>
      </template>

      <template v-if="values.diplomas && values.diplomas.length > 0">
        <v-divider />
        <v-row class="mt-0 mb-0 text-medium">
          <v-col cols="2" class="text-ellipsis text-nowrap grey--text" :title="$t('JOB.DIPLOMAS')">
            {{ $t('JOB.DIPLOMAS') }}
          </v-col>
          <v-col cols="10" class="text-ellipsis text-nowrap">
            <span>{{ values.diplomas.join(', ') }}</span>
          </v-col>
        </v-row>
      </template>

      <template v-if="values.quantity">
        <v-divider />
        <v-row class="mt-0 mb-0 text-medium">
          <v-col cols="2" class="text-ellipsis text-nowrap grey--text" :title="$t('JOB.QUANTITY')">
            {{ $t('JOB.QUANTITY') }}
          </v-col>
          <v-col cols="10" class="text-ellipsis text-nowrap">
            <span>{{ values.quantity }}</span>
          </v-col>
        </v-row>
      </template>

    </div>
  </div>
</template>

<script>

const _castString = (a) => {
  if (typeof a === 'string') {
    return a
  }
  return undefined
}

export default {
  name:"FormJobCompactAdvanced",
  components: {},
  data: (_this) => ({
    valid: false,
    dialog: false,
    periodItems: _this.$i18n.t('JOB.PERIOD_ITEMS'),
    currencyItems: _this.$i18n.t('JOB.CURRENCY_ITEMS'),
    experienceItems: _this.$i18n.t('JOB.EXPERIENCE_LEVEL_ITEMS'),
    educationItems: _this.$i18n.t('JOB.EDUCATION_LEVEL_ITEMS'),
    contratStatusItems: _this.$i18n.t('JOB.CONTRACT_STATUS_ITEMS'),
    //
    salary_min: undefined,
    salary_max: undefined,
    salary_period: 'year',
    salary_currency: 'EUR',
    duration_min: undefined,
    duration_max: undefined,
    duration_period: 'year',
    start_date: undefined,
    end_date: undefined,
    age_min: undefined,
    age_max: undefined,
    experience_level: undefined,
    education_level: undefined,
    diplomas: [],
    quantity: undefined,
  }),
  props: {
    values: {
      type: [Object, undefined],
      default: () => ({})
    }
  },
  computed: {
    filledFieldsCount () {
      return +!!this.protocol
    },
  },
  watch: {
    values: {
      handler () {
        this.attribValues()
      },
      deep: true,
    },
  },
  created () {
    this.attribValues()
  },
  methods: {

    // form fields
    apply () {
      if (this.$refs.form_protocol.validate()) {
        this.$emit('input', {
          salary_min: _castString(this.salary_min),
          salary_max: _castString(this.salary_max),
          salary_period: _castString(this.salary_period),
          salary_currency: _castString(this.salary_currency),
          duration_min: _castString(this.duration_min),
          duration_max: _castString(this.duration_max),
          duration_period: _castString(this.duration_period),
          start_date: _castString(this.start_date),
          end_date: _castString(this.end_date),
          age_min: _castString(this.age_min),
          age_max: _castString(this.age_max),
          experience_level: _castString(this.experience_level),
          education_level: _castString(this.education_level),
          diplomas: _castString(this.diplomas),
          quantity: _castString(this.quantity),
        })
        this.dialog = false
      }
    },
    closeDialog () {
      this.dialog = false
    },
    showDialog () {
      this.fileformat_fields_form = this.$clone(this.fileformat_fields)
      this.dialog = true
    },

    attribValues () {
      const values = this.$clone(this.values)
      Object.keys(values).forEach(key => {
        this[key] = values[key]
      })
    }
  }
}
</script>
